@charset "utf-8";
/* div.main */
div.main{
background: #00A651; /* Old browsers */
background: -moz-radial-gradient(center, ellipse cover,  #00A651 1%, #014E26 100%); /* FF3.6+ */
background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(1%,#00A651), color-stop(100%,#014E26)); /* Chrome,Safari4+ */
background: -webkit-radial-gradient(center, ellipse cover,  #00A651 1%,#014E26 100%); /* Chrome10+,Safari5.1+ */
background: -o-radial-gradient(center, ellipse cover,  #00A651 1%,#014E26 100%); /* Opera 12+ */
background: -ms-radial-gradient(center, ellipse cover,  #00A651 1%,#014E26 100%); /* IE10+ */
background: radial-gradient(ellipse at center,  #00A651 1%,#014E26 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00A651', endColorstr='#014E26',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
height:calc(100vh);
width:100%;
}

[class*="fontawesome-"]:before {
  font-family: 'FontAwesome', sans-serif;
}

/* ---------- GENERAL ---------- */

* {
  box-sizing: border-box;
  /*margin:0px auto;*/

  &:before,
  &:after {
    box-sizing: border-box;
  }

}

body {
   
    color: #606468;
  font: 87.5%/1.5em 'Open Sans', sans-serif;
  margin: 0;
  /*overflow: hidden;*/
 
}

button{
    color: #FFFFFF;
    background-color: #68B3C8;
    opacity: 1;
    filter: alpha(opacity=100);
    
}

a {
	color: #eee;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

/*input {
	border: none;
	font-family: 'Open Sans', Arial, sans-serif;
	font-size: 14px;
	line-height: 1.5em;
	padding: 0;
	-webkit-appearance: none;
}*/

p {
	line-height: 1.5em;
}

.clearfix {
  *zoom: 1;

  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }

}

.container {
  left: 85%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* ---------- LOGIN ---------- */

#login form{
	width: 250px;
}
#login{ /* .logo1 */
    display:inline-block;
    width:40%;
}
#login{
  margin:0 auto;
  max-width:500px;
/*border-right:1px solid #fff;*/
padding: 0px 22px;
width: 59%;
}
/*.logo1{
color:#fff;
font-size:50px;
  line-height: 125px;
}*/

#login form span.fa {
	background-color: #fff;
	border-radius: 3px 0px 0px 3px;
	color: #000;
	display: block;
	float: left;
	height: 50px;
    font-size:24px;
	line-height: 50px;
	text-align: center;
	width: 50px;
}

#login form input {
	height: 50px;
}
fieldset{
    padding:0;
    border:0;
    margin: 0;

}
#login form input[type="text"], input[type="password"] {
	background-color: #fff;
	border-radius: 0px 3px 3px 0px;
	color: #000;
	margin-bottom: 1em;
	padding: 0 16px;
	width: 200px;
}

#login form input[type="submit"] {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background-color: #000000;
  color: #eee;
  font-weight: bold;
  /* margin-bottom: 2em; */
  text-transform: uppercase;
  padding: 5px 10px;
  height: 30px;
}

#login form input[type="submit"]:hover {
	background-color: #d44179;
}

#login > p {
	text-align: center;
}

#login > p span {
	padding-left: 5px;
}
.middle {
  display: flex;
  width: 600px;
}

.boton{
  border-radius: 20px;
    box-sizing: border-box;
    border-width: 2px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    padding: 7px 18px;
    /*border-color: #66615B;
    color: #66615B;*/
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
}

.botonInicio{
  background-color:#014E26; 
  color:#FFFFFF; 
  border-color:#014E26;
}

.botonInicio:hover{
  background-color: #00A651;
  color: #FFFFFF;
  border-color: #00A651;
}
#tecladito{
  width: 129%;
}
#btnLogin{
  width: 129%;
}
#footerText{
  text-align: center; 
  width: 50%;
}

/*img {
  max-width: 100%;
  height: auto;
}*/


/*Media Querie 530*/
@media only screen and (min-width : 150px) and (max-width : 992px){
  #texto{
    display: none;
  }
  #iso{
    display: none;
  }
  .sello{
    display: none;
  }
  
}
@media only screen and (min-width : 150px) and (max-width : 600px){
  .sign-up, .no-access {
    margin:10px 0;
  }
  .login-button {
    margin-bottom:10px;
  }
  .logo1{
    display: none;
  }
  .container {
    left: 33%;
    right: 10%
  }
  #call{
    display: none;
}

#pin{
  margin-left: -4%
}
#documento{
  margin-left: -4%
}
#tecladito{
  width: 123%;
}
#btnLogin{
  width: 123%;
}
#footerText{
  text-align: center; 
  width: 100%;
  font-size: 8px
}
}


    

